import { Observable } from 'rxjs';
import {
  WorkStationCreateDto,
  WorkStationDto,
  WorkStationEditDto,
  WorkStationGetAllDto,
  WorkStationPageDto,
  WorkStationPageQueryParamsDto,
} from '../dtos/work-station.dto';

export abstract class WorkStationRepository {
  abstract getPage(
    params: WorkStationPageQueryParamsDto
  ): Observable<WorkStationPageDto>;
  abstract getAll(): Observable<WorkStationGetAllDto>;
  abstract create(
    workStation: WorkStationCreateDto
  ): Observable<WorkStationDto>;
  abstract update(workStation: WorkStationEditDto): Observable<WorkStationDto>;
  abstract delete(workStation: { id: number }): Observable<WorkStationDto>;
}
